body {
  margin: 0;
  font-family: "Poppins" !important;
}

.App {
  height: 100vh;
  width: 100vw;
}


.main-container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.header-start {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.header-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 660px;
}

.text-date p {
  font-size: 30px;
  margin: 20px;
  font-weight: bolder;
  align-items: center;
}

.text-start p {
  color: #0085ff;
}

.hyper-text p {
  color: #0085ff;
}


.dropdown-element {
  height: 150px;
}

.dropdown-div {
  height: 150px;
}

.content-main-container {
  width: 100%;
  height: 100%;
  display: block;
  justify-content: center;
}


.dropdown-spacing {
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.rectangle {
  border: 1px solid black;
  height: 70px;
  padding: 20px;
}

.error {
  font-size: 12px;
  color: var(--red-600);
  margin-top: 0.25rem;
}

.ant-table-thead .ant-table-cell {
  color: white;
  background-color: #181C33;
  font-family: Poppins;
  font-size: 14px;
  padding: 5px;
  /* font-weight: 500; */
  /* line-height: 37px; */
  /* letter-spacing: 0em; */
  /* text-align: left; */
}

.resource-table .ant-table-thead .ant-table-cell {
  color: #1F2544;
  background-color: #f2f2f2;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.resource-table .ant-table-tbody .ant-table-cell {
  border-right: 0px !important;
  font-size: 14px;
}

.resource-table .ant-table-container {
  border-radius: 10px;
  border: 1px solid rgba(160, 165, 186, 0.43);
}

.resource-table .ant-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 10px !important;
}

.resource-table .ant-table.ant-table-bordered>.ant-table-container {
  border-left: 1px solid rgba(160, 165, 186, 0.43);
}

.resource-table .ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 10px !important;
  padding-left: 16px !important;
}

.resource-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  border-top: 0;
}

.resource-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  border-top: 0;
}

.summary .ant-table-thead .ant-table-cell {
  color: #2143BC;
  border: 0.5px solid #ebecef !important;
  background-color: white !important;
  /* border-color: #2143BC !important  ;   */
  font-family: Poppins;
  font-size: 14px;
  padding: 0px 8px !important;
}

.summary .ant-table-thead .ant-table-cell:hover {
  color: #2143BC;
  border: 0.5px solid #ebecef !important;
  background-color: #F0F7FF !important;
  /* border-color: #F0F7FF !important;   */
  font-family: Poppins;
  font-size: 14px;
  padding: 0px 8px !important;
}

.inner-table .ant-table-thead .ant-table-cell {
  color: #2143BC;
  background-color: white !important;
  border-color: #2143BC;
  font-family: Poppins;
  font-size: 14px;
  padding: 0px 8px !important;
}

.inner-table .ant-table-thead .ant-table-cell:hover {
  color: #2143BC;
  background-color: #F0F7FF !important;
  border-color: #F0F7FF !important;
  font-family: Poppins;
  font-size: 14px;
  padding: 0px 8px !important;
}

.ant-table-tbody .ant-table-cell {
  font-family: Poppins;
  font-size: 12px;
  padding: 5px;
  /* width: 150; */
  /* font-weight: 500; */
  /* line-height: 37px; */
  /* letter-spacing: 0em; */
  /* text-align: left; */
}

.week-table .ant-picker {
  padding: 5px !important;
}

/* body .ant-table table {
  border-spacing: 0 16px;
} */
.ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 0px !important
}

.ant-table-container table>thead>tr:last-child th:last-child {
  border-top-right-radius: 0px !important
}

.card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #ffffff;
}

.card {
  border-left: none;
}


.pending {
  color: #FFB800;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.approved {
  color: #09A648;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.rejected {
  color: #E04E52;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.resubmitted {
  color: #8474e8;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.billable {
  color: #09A648;
}

.non-billable {
  color: #E04E52;
}

.hrs {
  font-size: 15px;
  color: #6d6d6e;
}

.ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-thead th.ant-table-column-sort {
  background: #181C33 !important;
}

.edit-button-icon .anticon {
  color: #2143BC;
}

.edit-button-disabled-icon .anticon {
  color: grey;
}

.delete-button-icon .anticon {
  color: red;
}

.delete-button-disabled-icon .anticon {
  color: grey;
}

.anticon-clock-circle {
  margin-top: -1px;
}

.nav-item .active {
  border-radius: 0 !important;
}

.nav-item a .active {
  background-color: none;
}

/* ::-webkit-scrollbar {
  display: none;
} */

/* #content-wrapper {
  height: 100vh;
  overflow-y: auto !important;
} */

.banner_enquiry .ant-alert-warning .ant-alert-icon {
  width: 21px !important;
  height: 21px !important;
}

.banner_enquiry .ant-alert-warning {
  background-color: #FFFBE7 !important;
}

.banner_enquiry .ant-alert-with-description {
  padding: 10px 10px 10px 10px;
}

.banner_resubmission .ant-alert-warning .ant-alert-icon {
  width: 21px !important;
  height: 21px !important;
}

.banner_resubmission .ant-alert-warning {
  background-color: #F2EFFF !important;
}

.banner_resubmission .ant-alert-with-description {
  padding: 10px 10px 10px 10px;
}

.link_resubmission .ant-btn-link {
  color: #E04E52;
}

.link_contactus .ant-btn-link {
  color: #1B2F78;
}

.custom_button .ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
}

.settings-icon {
  color: #1B2F78 !important;
  width: 32px !important;
  height: 32px !important;
}

.settings-icon:hover {
  background-color: #dfeaf5 !important;
  width: 32px !important;
  height: 32px !important;
}

.react-tour-content {
  margin-right: '20px';
}

.calendly-button .ant-btn-primary {
  background-color: #ffffff !important;
  color: #1B2F78 !important;
  border-color: rgb(153, 165, 211) !important;
  position: fixed;
  bottom: 50px;
  /* left: 40px; */
  z-index: 1000;
  /* Ensure the button is on top of other elements */
}

.ant-btn-primary {
  color: #ffffff !important;
  background-color: #1B2F78 !important;
  border-radius: '3.25px' !important;
  box-shadow: '0px 1.1267390251159668px 8.435643196105957px 0px #BEBEBE38' !important;
  font-family: 'Poppins' !important;
  font-size: '15px' !important;
  font-weight: '500';
  border: '0.5px solid';
  border-color: #1B2F78 !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  text-shadow: none;
  box-shadow: '0px 5.1267390251159668px 8.435643196105957px 0px #BEBEBE38';
  font-weight: '500';
  font-size: '15px';
  border-color: rgba(0, 0, 0, 0.25) !important;
}

.view-button .ant-btn-default:focus {
  color: #1B2F78 !important;
  box-shadow: none !important;
  font-family: 'Poppins';
  font-weight: '500';
  font-size: '15px';
  border-color: #E0E0E0 !important;
}

.view-button .ant-btn-default:hover {
  color: #2143BC !important;
  box-shadow: 0px 5.1267390251159668px 8.435643196105957px 0px #BEBEBE38 !important;
  font-family: 'Poppins';
  font-weight: '500';
  font-size: '15px';
  border-color: #2143BC !important;
}

.ant-btn-default:hover,
.ant-btn-default:focus {
  color: #2143BC !important;
  box-shadow: 0px 5.1267390251159668px 8.435643196105957px 0px #BEBEBE38 !important;
  font-family: 'Poppins';
  font-weight: '500';
  font-size: '15px';
  border-color: #2143BC !important;
}

.ant-picker:hover,
.ant-picker-focused {
  box-shadow: 0px 5.1267390251159668px 8.435643196105957px 0px #BEBEBE38 !important;
  font-weight: 500;
  font-size: 15px;
  border-color: #2143BC !important;
}

.ant-picker:hover .ant-badge,
.ant-picker-focused .ant-badge {
  transition: 0s !important;
  animation: none !important;
}

.ant-scroll-number-only {
  transition: 0s !important;
  animation: none !important;
}

.ant-picker-disabled:hover {
  box-shadow: 0px 5.1267390251159668px 8.435643196105957px 0px #BEBEBE38 !important;
  font-weight: 500;
  font-size: 15px;
  border-color: #d9d9d9 !important;
  border-bottom-style: "solid";
  border-bottom-width: 1px;
}

.ant-picker-week-panel-row-selected td {
  background-color: #1B2F78 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #1B2F78 !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: #2143BC !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #1B2F78 !important;
  border-color: #1B2F78 !important;

}

.ant-picker-today-btn {
  color: #2143BC !important;
}

.ant-picker-header-view button:hover {
  color: #2143BC !important;
}

.ant-select .ant-select-selector:hover {
  /* color: #2143BC !important; */
  border-color: #2143BC !important;
}

.ant-select.ant-select-disabled:hover .ant-select-selector {
  border-color: #d9d9d9 !important;
}

.ant-input:hover {
  border-color: #2143BC !important;
  border-right-width: 1px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1B2F78 !important;
  border-color: #1B2F78 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #1B2F78 !important;
}

.ant-checkbox-checked:after {
  border: #1B2F78 !important
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #1B2F78 !important;
  border: #1B2F78 !important
}

.ant-tabs-ink-bar {
  height: 5px;
  background: #2143BC !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2143BC !important;
}

.ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #2143BC !important;
}

.ant-tabs-tab:hover {
  color: #2143BC !important;
}

.ant-btn-link {
  color: #2143BC;
}

.ant-switch-checked {
  background-color: #1B2F78 !important;
}


.ant-table-filter-dropdown .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #1B2F78;
  background-color: #F0F7FF;
}

.ant-table-filter-dropdown-btns button.ant-btn-link:not([disabled]) {
  color: #1B2F78;
}

.ant-popover-buttons .ant-btn-default:hover {
  border-color: #1B2F78;
  ;
  color: #1B2F78;
}

/* Popover inner content */
.custom-popover .ant-popover-inner {
  background-color: black;
  border-radius: 10px;
}

.custom-popover .ant-popover-arrow-content {
  --antd-arrow-background-color: black;
}

.ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 1px !important;
}

.Toastify__progress-bar--info {
  background: #2143BC !important;
}

.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #2143BC !important;
}

.ant-pagination-item:hover,
.ant-pagination-item-active {
  border-color: #2143BC !important;
}

.ant-select-focused:not(.ant-select-disabled) .ant-select:not(.ant-select-cutomize-input) .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled):hover {
  border-color: #2143BC !important;

}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #2143BC !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #2143BC !important;
}

.shadowed-button {
  color: #1B2F78 !important;
}

.shadowed-button:hover {
  color: #1B2F78 !important;
  text-shadow: 0px 1px 3px rgba(33, 67, 188, 0.3) !important;
  letter-spacing: 0.02em;
}

.link_contactus .ant-btn-link:hover {
  color: #1B2F78 !important;
  text-shadow: 0px 1px 3px rgba(33, 67, 188, 0.3) !important;
  letter-spacing: 0.02em;
}

.link_resubmission .ant-btn-link:hover {
  color: #E04E52 !important;
  text-shadow: 0px 1px 3px rgba(224, 78, 82, 0.3) !important;
  letter-spacing: 0.02em;
}

.ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  color: #000000;
}

.ant-table-row-expand-icon-cell .ant-table-row-expand-icon:hover {
  color: #2143BC;
}

.ant-select-item-option-selected {
  color: #2143BC !important;
  background-color: #F0F7FF !important;
}

.spin-div {
  display: flex;
  justify-content: center;
}

#container1 {
  height: 100%;
  width: 100%;
  overflow: hidden !important;
}

#container2 {
  height: 90vh;
  overflow-y: scroll;
  padding: 20px;
}

#container2::-webkit-scrollbar {
  display: none;
}

.analysis{
  display: flex;
  justify-content: space-between;
}

.analysis-header {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  height: 36px;
  padding-bottom: 50px;
  /* padding-left: 2%; */
}

.analysis-opt-row {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
  padding-left: 2%;
  padding-right: 2%;
  gap: 15px;
}

.analysis-select {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.analysis-select span {
  padding-right: 5px;
}

.analysis-select .ant-select {
  min-height: 35px;
  /* height: 50px; */
  min-width: 182px;
  width: auto;
}

.analysis-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 45px;
  display: flex;
  align-items: center;
}

.analysis-select .ant-picker-range {
  min-height: 45px;
}

.analysis-select .ant-picker-range-separator {
  line-height: 0;
  /* height: 45px; */
}

.analysis-select .ant-select-multiple .ant-select-selector {
  display: flex;
  align-items: center;
  min-height: 35px;
  max-height: 150px;
  /* overflow-y: ; */
  width: 300px;
  max-width: 300px;
}


.analysis-card {
  /* width: 1137px; */
  /* height: 400px; */
  gap: 0px;
  border-radius: 10px !important;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
}

.analysis-card .ant-card-body {
  padding-top: 2%;
  padding-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px !important;
}

.analysis-card .ant-card-head {
  height: 48px;
  border-bottom: 0;
}

.no-access-modal {
  /* background-color: #A0A5BA !important; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-access-modal .no-access-text-image {
  /* margin-left: 40%; */
  /* padding-top: 8%; */
  width: 300px;
  height: 270px;
}

.no-access-modal .form-paragraph-title {
  /* margin-left: 40% !important; */
  /* padding-top: 10px !important; */
  font-family: 'Poppins';
}