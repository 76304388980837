.current-plan-card {
    max-width: 650px;
    border-radius: 10px;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
    font-family: Arial, sans-serif;
    border: 1px solid #A8A8A8;
    margin: 20px;
  }
  
  .header-row {
    border-bottom: 1px solid #A8A8A8;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }

  .current-plan-card .ant-card-head-title {
    font-size: 18px;
    font-weight: 500;
    color: #3357D8;
  }
  
  .header-billing {
    font-size: 14px;
    text-align: right;
    font-weight: 500;
    color: #636363;
  }
  
  .content-row {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 0.7px solid #A8A8A8;
  }
  
  .div-row {
    display: flex !important;
    flex-direction: column;
  }

  .label {
    font-size: 16px;
    font-weight: 400;
    color: #9B9B9B;
  }
  
  .value {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }
  
  .text {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }
  
  .button-row {
    align-items: center;
    align-self: center;
  }
  
  .current-plan-card .ant-card-body{
    padding: 20px;
  }
  
  .current-plan-card .ant-card-head {
    border-bottom: 1px solid #A8A8A8;
  }

  .current-plan-card .spinner-container {
    text-align: center;
    padding: 20px;
  }

  .current-plan-card .ant-alert-description{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }

  .current-plan-card .ant-alert-with-description{
    padding: 10px;
  }

  .current-plan-card .ant-alert-warning {
    border-radius: 10px;
  }
