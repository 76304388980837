.button-wrapper {
    display: flex;
    align-items: center;
    /* margin-bottom: 6px;  */
    /* Add margin between buttons */
  }
  
.custom-button {
    margin-top: 5px;
    /* margin-left: 4px; */
    margin-right: 8px;
    margin-bottom: 0px;
    width: 230px;
    height: 40px;
    text-align: left;
    padding: 10px; 
    background-color: #ffffff;
    color: #1B2F78;
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 20px;
    transition: margin 0.3s ease-in-out, padding 0.3s ease-in-out;;
  }
  
  .custom-button:hover {
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 5px;
    margin-bottom: 4px;
    background-color: #ECF0FF ;
    color: #1B2F78;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }

  .custom-image{
    /* margin-left : 4px; */
    margin-right: 8px;
    font-size: 20px;
    vertical-align: top
  }