.card{
    background: none;
    border: none;
    display: flex;
}

.antd-card{
    border-radius: 10px;
}

.tooltipDesign {
    background: rgba(24, 28, 51, 1);
    border-radius: 10px;
    color:white;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, 0);
    transition: all 0.1s ease;
    height:100px;
    width:100px;
}

.tooltipul{
    margin:0;
    list-style:none;
    padding: 0;
    text-align: center;
    font-weight: bold;
    font-family: 'Poppins';
    text-transform: uppercase;
}

.container{
    max-width :820;
    height: 240px;
    padding: 0px 2px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin:0;
}

.doughnut-container{
    width: 96%;
    max-width :98%;
    height: 240px;
    overflow-x: hidden;
    overflow-y: hidden;

}