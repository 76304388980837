body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}


.rowCustomStyling {
  background-color: #f1f6fc;
  border-top: 0.2px dashed #676879;
}

.opaque-col {
  opacity: 85%;
}

.nameOfDay-styling {
  font-weight: 400;
  color: #676879;
}

.corporateCustomStyling {
  background-color: #f5c8c8;
  border-top: 0.2px dashed #676879;
}

.personalCustomStyling {
  background-color: #d8f0c2;
  border-top: 0.2px dashed #676879;

}

.noFooterTimePick .ant-picker-footer {
  display: none;
}

.main-container {
  border-radius: 0 !important;
  -ms-overflow-style: none !important;
}

.form-container {
  background-color: white;
  border-radius: 18px;
  padding: 20px;
  text-align: center;
  gap: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  -ms-overflow-style: none !important;
}

.form-card {
  border: 1px solid rgb(168, 168, 168);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  -ms-overflow-style: none !important;
}

.close-button {
  position: relative;
  background: var(--background-color);
  border: 2px solid var(--background-color);
  color: var(--button-close-text);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  left: 45%;
}

.form-line-hidden {
  display: none;
}

.form-image {
  width: 30%;
  height: 50%;
  /* top: 13.23px;
  left: 178.5px; */
  gap: 0px;
  /* opacity: 0px; */
  /* width: 100px;
  height: auto; */
  margin-bottom: 10px;
}

.form-line {
  width: 100%;
  border: 1px solid #747474;
  margin: 20px 0;
}

.form-paragraph {
  margin: 0;
  padding: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-26);
  letter-spacing: 0.01em;
  text-align: center;
  white-space: pre-line;
}

.form-paragraph-title {
  width: 351px;
  height: 33px;
  opacity: 1;
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-700);
  line-height: var(--line-height-32);
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--text-color);
  padding: 0px;
  border-radius: 4px;
}

.form-paragraph-text {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  line-height: var(--font-size-26);
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--text-color);
  width: 490px;
  height: 78px;
  margin: 16px;
  padding: 0;
}

.see-plan-button {
  background-color: var(--button-background);
  color: var(--button-text);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-26);
  letter-spacing: 0.01em;
  text-align: center;
  /* border: none; */
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  width: 160px;
  height: 52px;
  margin-top: 20px;
}

.see-plan-button:hover {
  background-color: #595959;
}