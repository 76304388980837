.custom-datepicker .ant-picker{
    min-width: 210px !important;
    max-width: 250px !important;
}

/* .ant-table table{
    width:2400px
} */

.ant-table.ant-table-bordered th {
    padding: 4px !important;
}
.custom-column {
    .ant-table-column {
        width: 1px; /* Set a fixed width for all columns */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margin */
      }
  }