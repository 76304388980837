.nav-link{
  padding: 12px 12px;
}

/* Style for nav items */
.nav-item {
  transition: background-color 0.3s ease-in-out; /* Add transition for smoother hover effect */
  width: 100%; 
}

.nav-item:hover {
  background-color: rgb(70 73 92);
  border-radius: 10px;
  margin: 0rem 0rem !important; /* Adjust margin on hover */
}

.nav-item:hover .active{
  margin: 0rem 0rem !important;
  border-radius: 10px;
}

.nav-item .active {
  background-color: #1B2A60;
  margin: '0px 11px 0px 11px';
  border-radius: 10px!important;
}

/* .nav-item.active .nav-link {
  padding: 12px 12px;   */
  /* Match padding with the hovered state */
/* } */

.sidebar.toggled .nav-item .nav-link{
  width:100%!important;
}

.sidebar.toggled .nav-item .nav-link span{
  font-size: 8px!important;
}

.sidebar-toggled .nav-item.active{
  padding: 12px 12px; /* Adjusted padding when sidebar is toggled */
}

#collapsePages div .active {
  background-color: transparent !important;
}

.user-role {
font-family: 'Poppins';
font-size: 11.6px;
font-weight: 500;
line-height: 11px;
letter-spacing: 0.02em;
text-align: left;
color: #9E9E9E;
margin-bottom: 0.75rem;
}

.version {
  font-family: 'Poppins';
  font-size: 11.6px;
  font-weight: 300;
  line-height: 11px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #9E9E9E;
  margin-top: 0.75rem;
  }

.user-name {
  font-family: 'Poppins';
  font-size: 13.6px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #FFFFFF;
  white-space: nowrap; 
  text-overflow: ellipsis;
  overflow: hidden;
}


.circle {
  width: 36px;  /* You can adjust the size */
  height: 36px; /* You can adjust the size */
  background-color: #BB3255;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 14px; /* You can adjust the font size */
  font-weight: bold; /* Optional: to make the text bold */
}