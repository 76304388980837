.current-card {
    max-width: 650px;
    border-radius: 10px;
    border: 1px solid #A8A8A8;
    margin: 20px;
    font-family: Arial, sans-serif;
}

.current-card .label {
    font-family: Poppins, sans-serif;
    font-size: 19px; 
    font-weight: 600;
    line-height: 1.4;
    text-align: left;
    color: #1B2F78; 
    display: flex;
    align-items: center;
    gap: 8px; 
}

.current-card .label-container {
    display: flex;
    align-items: center; /* Align the items vertically in the center */
    gap: 8px; /* Space between the icon and text */
}

.current-card .label .warning-icon {
    font-size: 19px; 
    color: #1B2F78; 
}
.current-card img,
.current-card .anticon {
    width: 18.85px;
    height: 17.42px;
    opacity: 1;
    color: #1B2F78;
}

.current-card .subtitle {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color:#6E6E6E;
    margin-top: 8px;
}

.current-card .value {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin-top: 8px;
}

.current-card .livalue {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-top: 8px;
}

/* .current-card .text {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
} */

/* .current-card img {
    width: 18.85px;
    height: 17.42px;
    margin-right: 8px;
    color: #1B2F78;
} */

.contact-button {
    width: Fixed (121px)px;
    height: Fixed (10px)px;
    padding: 9.97px 8.31px 9.97px 8.31px;
    gap: 12.41px;
    border-radius: 4.16px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 1;
    background-color:#1B2F78;
    color: #FFFFFF;

    /* Font properties */
    font-family: 'Poppins', sans-serif; 
    font-size: 14px; 
    font-weight: 400; 
    line-height: 20px; 
    letter-spacing: 0.01em; 
    text-align: center; 
    text-underline-position: from-font; 
    text-decoration-skip-ink: none;
    transition: margin 0.3s ease-in-out, padding 0.3s ease-in-out;
} 

.contact-button:hover {
    background: #163A6D; 
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); 
}

.button-container {
    display: flex;
    justify-content: flex-end;
}

.current-card .ant-card-body {
    padding: 20px;
}

.current-card .ant-card-head {
    border-bottom: 1px solid #A8A8A8;
}
